import React, { useEffect, useRef, useState } from 'react';
import './LandingHeader.scss';
import {
  CloseWhiteIcon,
  HamburgerMenu,
  UserRound,
  WezircleMobileHeader,
  WezircleWhite,
} from '../../../../img';
import { Button } from '../../forms';

type LandingHeaderProps = {
  refs: React.RefObject<any>[];
};

const LandingHeader = ({ refs }: LandingHeaderProps) => {
  const [isLinkContainerVisibleMobile, setIsLinkContainerVisibleMobile] =
    useState(false);
  const [isLandingHeaderMobile, setIsLandingHeaderMobile] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const goToDiv = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView();
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      if (entries[0].target === containerRef.current) {
        if (containerRef.current.offsetHeight !== 104) {
          setIsLandingHeaderMobile(true);
        } else {
          setIsLandingHeaderMobile(false);
        }
      }
    });
    if (containerRef.current != null) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  const getHeaderContent = () => {
    if (!isLandingHeaderMobile) {
      return (
        <div ref={containerRef} className="landing-header-container">
          <div className="content">
            <img src={WezircleWhite} alt="Wezircle" />
            <div className="links-container">
              <span className="link" onClick={() => goToDiv(refs[0])}>
                Crea tu Zircle
              </span>
              <span className="link" onClick={() => goToDiv(refs[1])}>
                ¿Cómo funciona?
              </span>
              <span className="link" onClick={() => goToDiv(refs[2])}>
                Beneficios
              </span>
              <span className="link" onClick={() => goToDiv(refs[3])}>
                Próposito
              </span>
              <span className="link" onClick={() => goToDiv(refs[4])}>
                Contacto
              </span>
            </div>
            <div>
              <a href="https://app.wezircle.com" target="_self">
                <Button
                  text="Accede a tu Zircle"
                  size="lg"
                  onClick={() => null}
                />
              </a>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div ref={containerRef} className="landing-header-container">
          <div className="content">
            <img
              className="hamburger-icon"
              src={
                isLinkContainerVisibleMobile ? CloseWhiteIcon : HamburgerMenu
              }
              onClick={() =>
                setIsLinkContainerVisibleMobile(!isLinkContainerVisibleMobile)
              }
            />
            <img src={WezircleMobileHeader} />
            <div>
              <a href="https://app.wezircle.com" target="_self">
                <Button icon={UserRound} size="lg" onClick={() => null} />
              </a>
            </div>
          </div>
          {isLinkContainerVisibleMobile && (
            <div className="links-container">
              <span
                className="link"
                onClick={() => {
                  goToDiv(refs[0]);
                  setIsLinkContainerVisibleMobile(false);
                }}
              >
                Crea tu Zircle
              </span>
              <span
                className="link"
                onClick={() => {
                  goToDiv(refs[1]);
                  setIsLinkContainerVisibleMobile(false);
                }}
              >
                ¿Cómo funciona?
              </span>
              <span
                className="link"
                onClick={() => {
                  goToDiv(refs[2]);
                  setIsLinkContainerVisibleMobile(false);
                }}
              >
                Beneficios
              </span>
              <span
                className="link"
                onClick={() => {
                  goToDiv(refs[3]);
                  setIsLinkContainerVisibleMobile(false);
                }}
              >
                Propósito
              </span>
              <span
                className="link"
                onClick={() => {
                  goToDiv(refs[4]);
                  setIsLinkContainerVisibleMobile(false);
                }}
              >
                Contacto
              </span>
            </div>
          )}
        </div>
      );
    }
  };
  return getHeaderContent();
};

export { LandingHeader };
