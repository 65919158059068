import React, { useCallback, useEffect, useState } from 'react';
import { Button, Input, Link } from '../../../reusable';
import './Login.scss';
import { LoginData, postLogin } from '../../../../services/apiService';
import { setExpiringLocalStorageItem, validateEmail } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { useApi } from '../../../../hooks';

type LoginProps = {
  clickOnForgivenPasswords: () => void;
  onLoginSuccess: (token: string) => void;
};
const Login: React.FC<LoginProps> = ({
  clickOnForgivenPasswords,
  onLoginSuccess,
}: LoginProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);

  const navigate = useNavigate();

  const {
    execute: submitLogin,
    isLoading,
    data,
    error,
  } = useApi<{ session: string }, LoginData>(postLogin);

  useEffect(() => {
    const prevEmail = localStorage.getItem('user_email');
    if (prevEmail) setEmail(JSON.parse(prevEmail));
  }, []);

  useEffect(() => {
    if (data?.success) {
      setExpiringLocalStorageItem(
        'user_token',
        { session: data.session },
        3540 * 1
      );
      setExpiringLocalStorageItem('user_email', email.toLowerCase(), 3540 * 1);
      onLoginSuccess(data.session);
    } else if (error) {
      console.log(error);
    }
  }, [data?.session, data?.success, email, error, onLoginSuccess]);

  const checkEmail = useCallback(() => {
    setIsEmailValid(validateEmail(email));
  }, [email]);

  const goToForgivenPassword = () => {
    clickOnForgivenPasswords();
  };

  const goToRegister = () => {
    navigate('/join-zircle');
  };

  const onPressEnterSubmit = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      submitLogin({ email, password });
    }
  };

  return (
    <div className="Login-container">
      <span className="Login-title">Login en Zircle</span>
      <div className="Login-credentials">
        <div className="Login-email-container">
          <Input
            errorMessage={!isEmailValid ? 'Error de formato' : ''}
            onBlur={() => email && checkEmail()}
            onChange={e => setEmail(e.target.value)}
            title="Tu email"
            type="email"
            value={email}
          />
        </div>
        <div className="Login-password-container">
          <Input
            title="Contraseña"
            type="password"
            onChange={e => setPassword(e.target.value)}
            onKeyDown={onPressEnterSubmit}
            value={password}
            errorMessage={error ? 'Credenciales incorrectas' : ''}
          />
        </div>
        <div className="Login-forgiven-password-container">
          <Link
            onClick={goToForgivenPassword}
            text="He olvidado mi contraseña"
          />
        </div>
      </div>
      <div className="bottom-container">
        <div className="Login-continue">
          <Button
            onClick={() => submitLogin({ email, password })}
            disabled={isLoading || !email || !isEmailValid}
            text="Continuar"
          />
        </div>
        <div className="Login-register-container">
          <Link onClick={goToRegister} text="No tengo cuenta. Registrarme" />
        </div>
      </div>
    </div>
  );
};

export default Login;
