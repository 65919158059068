import { httpCode } from '../constants';

const BASE_URL_contact_us =
  'https://xstzd14ggl.execute-api.eu-west-3.amazonaws.com';
const BASE_URL = 'https://kg2j639w00.execute-api.eu-west-3.amazonaws.com';

type ContactUsData = {
  name: string;
  email: string;
  message: string;
  phone: string;
};

export async function postContactUs(postData: ContactUsData) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    };
    const response = await fetch(
      `${BASE_URL_contact_us}/prod/contact-us`,
      requestOptions
    );
    const data = await response.json();
    if (data.statusCode === httpCode.success) {
      return data.body;
    }
  } catch (error) {
    throw new Error('Error fetching data');
  }
}

export type LoginData = {
  email: string;
  password: string;
};

export async function postLogin(credentials: LoginData) {
  try {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(credentials),
    };
    const response = await fetch(`${BASE_URL}/dev/login`, requestOptions);

    const data = await response.json();
    if (data.session) {
      return { success: true, ...data };
    } else {
      return { success: false, ...data };
    }
  } catch (error) {
    console.error(error);
    return { success: false, message: error };
    throw new Error('Error fetching data');
  }
}
