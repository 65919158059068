import React from 'react';
import './Button.scss';

type ButtonProps = {
  children?: React.ReactNode;
  text?: string;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  type?: string;
  size?: string;
  disabled?: boolean;
  icon?: string;
};

const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const getIcon = () => {
    if (props.icon) {
      return <img src={props.icon} alt="icon" />;
    }
  };

  const getRoundShape = () => {
    if (props.text === undefined || props.children === undefined) {
      return 'round';
    } else {
      return '';
    }
  };

  return (
    <div
      className={`btn btn-${props.type} ${getRoundShape()} ${props.size} ${
        props.disabled && 'disabled'
      }`}
      onClick={e => !props.disabled && props.onClick(e)}
    >
      {getIcon()}
      {(props.text || props.children) && (
        <label className="label">
          {props.text}
          {props.children}
        </label>
      )}
    </div>
  );
};

Button.defaultProps = {
  type: 'primary',
  disabled: false,
  size: 'lg',
};

export { Button, type ButtonProps };
