import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input, Link } from '../../../../reusable';
import {
  checkUser,
  checkEmail as apiCheckEmail,
} from '../../../../../services/userService';
import '../JoinZircle.scss';
import { validateEmail, validatePassword } from '../../../../../utils';
import { CloseRedIcon } from '../../../../../img';
import { addUserToZircle } from '../../../../../services/zircleService';
//import { landingUrl } from '../../../../../constants';

type secondStepData = {
  username: string;
  email: string;
  password: string;
  phone: string;
};

type JoinZircleSecondStepProps = {
  initialData: secondStepData;
  onNextStep: (data: secondStepData) => void;
  zircle: string;
};

function JoinZircleSecondStep({
  initialData,
  onNextStep,
  zircle,
}: JoinZircleSecondStepProps) {
  const navigate = useNavigate();

  const [username, setUsername] = useState(initialData.username);
  const [userAvailable, setUserAvailable] = useState(true);
  const [email, setEmail] = useState(initialData.email);
  const [phone, setPhone] = useState(initialData.phone);
  const [emailAvailable, setEmailAvailable] = useState(true);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [password, setPassword] = useState(initialData.password);
  const [isPasswordStrong, setIsPasswordStrong] = useState(true);

  const addToZircle = async () => {
    const addUserCall = await addUserToZircle({
      email,
      nickName: username,
      password,
      zircle,
    });
    console.log(addUserCall);
    onNextStep({ username, email, password, phone });
  };

  const isDataValid = useCallback(() => {
    const emailCheck = email.length && isEmailValid;
    const passwordCheck = password.length && isPasswordStrong;
    const usernameCheck = username.length >= 4 && userAvailable;
    return emailCheck && passwordCheck && usernameCheck;
  }, [
    email.length,
    isEmailValid,
    isPasswordStrong,
    password.length,
    userAvailable,
    username.length,
  ]);

  const checkEmail = useCallback(async () => {
    await setIsEmailValid(validateEmail(email));
    if (isEmailValid) {
      const checkup = await apiCheckEmail({ zircle, email });
      setEmailAvailable(Boolean(checkup));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const checkPassword = () => {
    setIsPasswordStrong(validatePassword(password));
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPassword(e.target.value);
    if (!isPasswordStrong) {
      checkPassword();
    }
  };

  const checkUsername = useCallback(async () => {
    const checkup = await checkUser({ zircle, name: username });
    setUserAvailable(Boolean(checkup));
  }, [username, zircle]);

  return (
    <div className="join-zircle__step-container">
      <div className="join-zircle-title">Regístrate en Wezircle</div>
      <div className="join-zircle-message">
        Para poder crear un nuevo Zircle, debes registrarte primero.
      </div>

      <div className="join-zircle-input">
        <Input
          charLimitCount={30}
          errorMessage={
            !userAvailable ? 'Este usuario ya existe. Busca otro nombre' : ''
          }
          helper={
            userAvailable ? 'Debe ser único en tu Zircle y sin espacios' : ''
          }
          name="name"
          onBlur={() => username && checkUsername()}
          onChange={e => setUsername(e.target.value)}
          title="Nombre propio"
          type="text"
          value={username}
        />
      </div>
      <div className="join-zircle-input">
        <Input
          errorMessage={
            !isEmailValid || !emailAvailable ? 'Email no valido' : ''
          }
          name="name"
          onBlur={() => email && checkEmail()}
          onChange={e => setEmail(e.target.value)}
          title="Email con el que quieras registrarte"
          type="email"
          value={email}
        />
      </div>
      <div className="join-zircle-input">
        <Input
          name="name"
          onChange={e => setPhone(e.target.value)}
          title="Telefono de contacto"
          type="tel"
          value={phone}
        />
      </div>
      <div className="join-zircle-input">
        <Input
          error={!isPasswordStrong}
          type="password"
          name="name"
          title="Contraseña"
          onBlur={() => password && checkPassword()}
          onChange={handlePasswordChange}
          value={password}
        />
        {!isPasswordStrong && (
          <>
            <div className="Input-additional-row" key="error_message_1">
              <div className="Input-error-feedback">
                <img src={CloseRedIcon} className="alert" alt="alert-icon" />
                <span className="error-text">
                  Debe incluir al menos 8 caracteres alfanuméricos
                </span>
              </div>
            </div>
            <div className="Input-additional-row" key="error_message_2">
              <div className="Input-error-feedback">
                <img src={CloseRedIcon} className="alert" alt="alert-icon" />
                <span className="error-text">
                  Debe incluir mayúsculas y minúsculas
                </span>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="join-zircle__bottom-container">
        <div className="join-zircle__second-step__privacy-text">
          Al continuar, acepto la{' '}
          <Link
            onClick={() =>
              window.open(
                `https://wezircle.s3.eu-west-3.amazonaws.com/privacy.pdf`,
                '_blank'
              )
            }
            text="política de privacidad"
          />
        </div>
        <Button
          onClick={addToZircle}
          disabled={!isDataValid()}
          size="sm"
          text="Continuar"
        />
        <div className="join-zircle__center-container">
          <Link onClick={() => navigate('/login')} text="Ya tengo cuenta" />
        </div>
      </div>
    </div>
  );
}

export default JoinZircleSecondStep;
