import React from 'react';
import { Provider } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';
import {
  AddPost,
  ContactUs,
  Home,
  JoinZircle,
  Landing,
  PostDetail,
  Profile,
  Search,
  SellingPost,
  Settings,
  WithAuthCheck,
} from './components/views';
import { Menu, MobileHeader } from './components/reusable';
import store from './store';
import './App.css';
import ScrollToTop from './utils/ScrollToTop';

const Main: React.FC = () => {
  const location = useLocation();
  const isLanding =
    location.pathname === '/' ||
    location.pathname === '/login' ||
    location.pathname === '/join-zircle';

  const getMobileHeader = () => {
    if (!isLanding) {
      return <MobileHeader />;
    }
  };

  return (
    <div className={`screen-container ${isLanding ? 'landing' : ''}`}>
      {getMobileHeader()}
      <Menu />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Landing />} />
        <Route
          path="/home"
          element={
            <WithAuthCheck>
              <Home />
            </WithAuthCheck>
          }
        />
        <Route
          path="/profile"
          element={
            <WithAuthCheck>
              <Profile />
            </WithAuthCheck>
          }
        />
        <Route
          path="/search"
          element={
            <WithAuthCheck>
              <Search />
            </WithAuthCheck>
          }
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/join-zircle" element={<JoinZircle />} />
        <Route
          path="/posts/:id"
          element={
            <WithAuthCheck>
              <PostDetail />
            </WithAuthCheck>
          }
        />
        <Route
          path="/profile/posts/:id"
          element={
            <WithAuthCheck>
              <PostDetail />
            </WithAuthCheck>
          }
        />
        <Route
          path="/posts/new"
          element={
            <WithAuthCheck>
              <AddPost />
            </WithAuthCheck>
          }
        />
        <Route
          path="/posts/edit/:id"
          element={
            <WithAuthCheck>
              <AddPost />
            </WithAuthCheck>
          }
        />
        <Route
          path="/posts/close/:id"
          element={
            <WithAuthCheck>
              <SellingPost />
            </WithAuthCheck>
          }
        />
        <Route
          path="/settings"
          element={
            <WithAuthCheck>
              <Settings />
            </WithAuthCheck>
          }
        />
      </Routes>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop />
        <Main />
      </Router>
    </Provider>
  );
};

export default App;
