import React from 'react';
import './ArrowNavigation.scss';
import { ArrowGreen } from '../../../../img';

enum ArrowDirection {
  UP = 'up',
  RIGHT = 'right',
  DOWN = 'down',
  LEFT = 'left',
}

type ArrowNavigationProps = {
  direction: ArrowDirection;
  onClick?: () => void;
};

const ArrowNavigation: React.FC<ArrowNavigationProps> = ({
  direction,
  onClick,
}: ArrowNavigationProps) => {
  return (
    <div
      className={'arrow-navigation-container' + onClick ? 'clickable' : ''}
      onClick={onClick}
    >
      <img
        className={direction === ArrowDirection.UP ? '' : direction}
        src={ArrowGreen}
      />
    </div>
  );
};

export { ArrowNavigation, ArrowDirection };
