import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { searchPosts } from '../../../services/postService';
import './Profile.scss';
import { ProfileCards } from './components/profile-cards';
import { getExpiringLocalStorageItem } from '../../../utils';
import { PostType } from '../../../types';
import { PostSearchItem } from '../../reusable';

const Profile = () => {
  const location = useLocation();
  const [posts, setPosts] = useState<PostType[]>([]);
  const queryParams = new URLSearchParams(location.search);
  const userId: string = getExpiringLocalStorageItem('user_email') || '';

  const profileId = queryParams.get('profileId') || userId;
  const isOwnProfile = userId === profileId;

  useEffect(() => {
    const fetchData = async () => {
      const postsResult = await searchPosts();
      setPosts(
        postsResult?.filter(post => post.user.email === profileId) ?? []
      );
    };

    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile__container">
      <ProfileCards profileId={profileId} isOwnProfile={isOwnProfile} />
      <div className="post-list">
        {posts.map(post => (
          <PostSearchItem
            {...post}
            fromProfile={true}
            key={`post_${post.id}`}
          />
        ))}
      </div>
    </div>
  );
};

export { Profile };
