import React from 'react';
import './CookieBanner.scss';
import { Button } from '../../forms';

type CookieBannerProps = {
  onClose: () => void;
};

const CookieBanner: React.FC<CookieBannerProps> = ({
  onClose,
}: CookieBannerProps) => {
  return (
    <div className="cookie-banner__container">
      <div className="cookie-banner__content">
        <span className="text">
          Este portal web únicamente utiliza cookies propias con finalidad
          técnica, no recaba ni cede datos de carácter personal de los usuarios
          sin su conocimiento.
        </span>
        <div className="buttons-container">
          <Button
            text="Más información"
            type="secondary"
            onClick={() =>
              window.open(
                `https://wezircle.s3.eu-west-3.amazonaws.com/privacy.pdf`,
                '_blank'
              )
            }
          />
          <Button text="Aceptar y cerrar" onClick={onClose} />
        </div>
      </div>
    </div>
  );
};

export { CookieBanner, type CookieBannerProps };
