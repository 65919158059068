import React from 'react';
import './ContactUs.scss';
import { Button, Input, Link } from '../../reusable';
import Checkbox from '@mui/material/Checkbox';
import { useContactForm } from './useContactForm';
import { postContactUs } from '../../../services/apiService';

const ContactUsLanding: React.FC = () => {
  const handleSubmittedForm = async (response: string) => {
    console.log(response);
    const res = await postContactUs(formData);
    console.log(res);
  };

  const {
    formData,
    handleCheckboxChange,
    handleInputChange,
    handleSubmit,
    isChecked,
    isFormComplete,
  } = useContactForm({
    onSubmitted: handleSubmittedForm,
  });

  return (
    <div className="contact-us-body">
      <div className="contact-us-title landing">Contacta con nosotros</div>

      <div className="my-form" onSubmit={handleSubmit}>
        <div className="contact-us-input">
          <Input
            name="name"
            onChange={handleInputChange}
            title="Nombre y apellidos"
            type="text"
            value={formData.name}
          />
        </div>

        <div className="contact-us-input">
          <Input
            name="phone"
            onChange={handleInputChange}
            title="Teléfono"
            type="tel"
            value={formData.phone}
          />
        </div>

        <div className="contact-us-input">
          <Input
            name="email"
            onChange={handleInputChange}
            title="Email de contacto"
            type="email"
            value={formData.email}
          />
        </div>

        <div className="contact-us-input">
          <Input
            name="message"
            onChange={handleInputChange}
            title="Cuéntanos que necesitas"
            type="textarea"
            value={formData.message}
          />
        </div>

        <div className="contact-us-input checkbox">
          <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
          Estoy de acuerdo con los{' '}
          <Link
            onClick={() =>
              window.open(
                `https://wezircle.s3.eu-west-3.amazonaws.com/privacy.pdf`,
                '_blank'
              )
            }
            text="términos y condiciones"
          />
        </div>
      </div>
      <Button
        onClick={handleSubmit}
        disabled={!isFormComplete}
        text="Continuar"
      />
    </div>
  );
};

export default ContactUsLanding;
