import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './PostDetail.scss';
import { getPostDetail, setPostComment } from '../../../services/postService';
import { CommentType, PostType, ReactionsType } from '../../../types';
import {
  AdaptiveDialog,
  Carousel,
  EmojiPickerMenu,
  InputComment,
  MobileModalMenu,
  PostHeader,
  PostInfo,
  Tag,
} from '../../reusable';
import { formatDate, getExpiringLocalStorageItem } from '../../../utils';
import { imgRoute } from '../../../constants';
import { useScreenType } from '../../../hooks';
import { LeftGreen } from '../../../img';
import { Comments } from '../../reusable/post';

function PostDetail() {
  const { id, uploaded } = useParams();
  const navigate = useNavigate();
  const screenType = useScreenType();
  const [details, setDetails] = useState<PostType>();
  const [newComment, setNewComment] = useState('');
  const [reactions, setReactions] = useState<ReactionsType>({});
  const [isMyPost, setIsMyPost] = useState(false);
  const [comments, setComments] = useState<CommentType[]>([]);
  const [showUploadedConfirmation, setShowUploadedConfirmation] = useState(
    Boolean(uploaded)
  );

  useEffect(() => {
    if (!id) return;
    window.scrollTo(0, 0);

    const userEmail = getExpiringLocalStorageItem<string>('user_email');
    const fetchData = async () => {
      const detailsResult = await getPostDetail(id);
      setDetails(detailsResult);
      setComments(detailsResult.comments);
      setReactions(detailsResult.reactions);
      return detailsResult;
    };

    fetchData()
      .then(data => {
        setIsMyPost(userEmail === data?.user.email);
      })
      .catch(console.error);
  }, [id]);

  const onSendComment = async () => {
    if (!id || !newComment) return;
    const newComments = await setPostComment(id, newComment);

    if (newComments) {
      setComments(newComments);
    }
    setNewComment('');
  };

  const getUploadedConfirmationContent = () => {
    if (showUploadedConfirmation) {
      return (
        <div className="uploaded-post-confirmation-container">
          <div className="uploaded-post-confirmation"></div>
          <div className="uploaded-post-confirmation-description"></div>
        </div>
      );
    }
  };

  const getUploadedConfirmation = () => {
    if (screenType === 'mobile')
      return (
        <MobileModalMenu
          isOpen={showUploadedConfirmation}
          onClose={() => setShowUploadedConfirmation(false)}
        >
          {getUploadedConfirmationContent()}
        </MobileModalMenu>
      );
    else if (uploaded) {
      return (
        <AdaptiveDialog>{getUploadedConfirmationContent()}</AdaptiveDialog>
      );
    }
  };

  const redirectToTeamsChat = () => {
    () => window.open(details?.teamsLink, '_blank');
  };

  return (
    <div className="detail-container">
      {window.history.length > 1 && (
        <div
          className="post-detail__navigate-back clickable"
          onClick={() => navigate(-1)}
        >
          <img src={LeftGreen} alt="<" />
          Volver
        </div>
      )}
      {getUploadedConfirmation()}
      {details?.user && (
        <PostHeader
          postId={id}
          isMyPost={isMyPost}
          postType={details.postType}
          redirect={!isMyPost ? redirectToTeamsChat : undefined}
          user={details.user}
        />
      )}
      <div className="desktop-split">
        <div className="images-row">
          {!!details?.images.length && (
            <Carousel
              baseRoute={imgRoute}
              images={details.images}
              tag={<Tag type={details?.postType ?? undefined} text="ok" />}
            />
          )}
        </div>
        <div className="post-detail__content-row">
          {details && (
            <PostInfo
              id={id}
              co2={details.co2}
              description={details.description}
              price={details.price}
              title={details.title}
              isMyPost={isMyPost}
            />
          )}
          {!!details?.datePublished && (
            <div className="post-detail__date-row">
              Publicado el {formatDate(details.datePublished)}
            </div>
          )}
          {id && (
            <EmojiPickerMenu
              id={id}
              onEmojiAdded={setReactions}
              reactions={reactions}
            />
          )}
          <div className="separator" />
          <div className="post-detail__comments">
            {comments?.length ? (
              <Comments commentsList={comments} />
            ) : (
              <div className="no-comment-message">
                No hay comentarios. ¡Escribe el primero!
              </div>
            )}
          </div>
          <InputComment
            onChange={e => setNewComment(e.target.value)}
            onSend={onSendComment}
            value={newComment}
          />
        </div>
      </div>
    </div>
  );
}

export { PostDetail };
