import React from 'react';
import './AdaptiveSidenav.scss';

type AdaptiveSidenavProps = {
  children: React.ReactNode;
  onBlur: () => void;
};

const AdaptiveSidenav: React.FC<AdaptiveSidenavProps> = ({
  children,
  onBlur,
}: AdaptiveSidenavProps) => {
  return (
    <div className="adaptive-sidenav__container" onBlur={onBlur}>
      <div className="adaptive-sidenav__content">{children}</div>
    </div>
  );
};

export { AdaptiveSidenav, type AdaptiveSidenavProps };
