import React, { useEffect, useState } from 'react';
import './SellingPost.scss';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getPostDetail,
  setPostAsClosed,
} from '../../../../services/postService';
import { BagOK, Bin, Illustration } from '../../../../img';
import { AvatarProfile, Button, Input, Link } from '../../../reusable';
import { PostPurposeType, UserType } from '../../../../types';
import { getZircleInfo, searchUsers } from '../../../../services/zircleService';

const actionDone: Record<PostPurposeType, string> = {
  gift: 'regalado',
  rent: 'alquilado',
  sell: 'vendido',
  share: 'compartido',
};

const SellingPost: React.FC = () => {
  const emptyTom = { id: '', phone: '', nick: '', email: '', avatar: '' };
  const [zircleName, setZircleName] = useState('');
  const [points, setPoints] = useState<number>(0);
  const [type, setType] = useState<PostPurposeType>('sell');
  const [tom, setTom] = useState<UserType>(emptyTom);
  const [possibleToms, setPossibleToms] = useState<UserType[]>([]);
  const [userQuery, setUserQuery] = useState<string>('');
  const [userId, setUserId] = useState<string>('');
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    getPostDetail(id ?? '')
      .then(postDetails => {
        console.log(postDetails);
        setPoints(Number(postDetails.co2));
        setType(postDetails.postType);
        setUserId(postDetails.user.id);
      })
      .catch(e => {
        console.error(e);
      });
    getZircleInfo('estrella').then(zircleResult => {
      setZircleName(zircleResult.displayName);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeTom = () => {
    setTom(emptyTom);
  };

  const getPossibleToms = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = e.target.value;
    setPossibleToms(await searchUsers(value));
    setUserQuery(value);
  };

  const onSelectTom = (tomSelected: UserType) => {
    setTom(tomSelected);
  };

  const getInputOrUserFound = () => {
    if (tom.nick.length > 0) {
      return (
        <div className="user-found-container">
          <AvatarProfile image={tom.avatar} width={40} height={40} />
          <span className="text">{tom.nick}</span>
          <div className="change-container">
            <Link onClick={removeTom} text="Cambiar" iconL={Bin} />
          </div>
        </div>
      );
    } else {
      return (
        <div className="search-user-container">
          <Input
            title={`Nombre del usuario de ${zircleName}`}
            onChange={getPossibleToms}
            value={userQuery}
          />
        </div>
      );
    }
  };

  const getPossibleTomsView = () => {
    return possibleToms.reduce((tomList: React.ReactNode[], possibleTom) => {
      if (possibleTom.email === userId) return tomList;
      tomList.push(
        <div
          key={possibleTom.email}
          className="user-container clickable"
          onClick={() => onSelectTom(possibleTom)}
        >
          <AvatarProfile image={possibleTom.avatar} width={40} height={40} />
          <span className="text">{possibleTom.nick}</span>
        </div>
      );
      return tomList;
    }, []);
  };

  const closePostDeal = async () => {
    const closed = await setPostAsClosed(Number(id), tom.email);

    if (closed) {
      navigate('/home');
    } else {
      console.error('There was an error with the backend service');
    }
  };

  const getPossibleTomsOrPoints = () => {
    if (
      userQuery.length > 0 &&
      possibleToms.length > 0 &&
      tom.nick.length === 0
    ) {
      return <div className="result-container">{getPossibleTomsView()}</div>;
    } else if (
      userQuery.length > 0 &&
      possibleToms.length === 0 &&
      tom.nick.length === 0
    ) {
      return (
        <div className="no-result-found-container">
          <img className="icon" src={Illustration} />
          <span className="text">No se ha encontrado ningún resultado</span>
        </div>
      );
    } else if (tom.nick.length > 0) {
      return (
        <div className="confirm-deal-container">
          <span className="title">Confirmación de trato cerrado</span>
          <div className="points-container">
            <span className="title">
              Tus puntos son
              <span className="bold">{' ' + points + ' '}</span>y equivale a:
            </span>
            <span className="points">{points + ' Kg de CO2'}</span>
          </div>
          <span className="description">
            Al continuar, confirmas que has cerrado un trato con este usuario,
            <span className="bold">
              {' por lo que los dos obtendréis los puntos.'}
            </span>
          </span>
          <div className="confirm-deal-button-container">
            <Button onClick={closePostDeal} text="Confirmar usuario" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="selling-post-container">
      <img className="icon" src={BagOK} />
      <span className="title">{`¿A quién se lo has ${actionDone[type]}?`}</span>
      <span className="description">
        Selecciona al integrante de Estrella con el que has cerrado el trato
        para que ambos veáis reflejado los puntos de impacto medioambiental.
        {/* TODO: Dynamic zircle */}
      </span>{' '}
      {getInputOrUserFound()}
      {getPossibleTomsOrPoints()}
    </div>
  );
};

export { SellingPost };
