import React from 'react';
import './ContactUs.scss';
import { Button, Input, Link } from '../../reusable';
import Checkbox from '@mui/material/Checkbox';
import { AdaptiveDialog } from '../../reusable/dialog/adaptive-dialog/AdaptiveDialog';
import { useContactForm } from './useContactForm';
import { postContactUs } from '../../../services/apiService';

const ContactUs: React.FC = () => {
  const handleSubmittedForm = async (response: string) => {
    console.log(response);
    const res = await postContactUs(formData);
    console.log(res);
  };

  const {
    formData,
    handleCheckboxChange,
    handleInputChange,
    handleSubmit,
    isChecked,
    isFormComplete,
  } = useContactForm({
    onSubmitted: handleSubmittedForm,
  });

  return (
    <AdaptiveDialog>
      <div className="contact-us-body">
        <div className="contact-us-title">Contacta con nosotros</div>

        <div className="contact-us-message">
          Si te interesa muestro plan intermedio o premium, cuéntanos un poco
          más sobre la comunidad que quieres crear, quién eres o sois (una
          empresa, un club, ...) y...
        </div>

        <form className="my-form" onSubmit={handleSubmit}>
          <div className="contact-us-input">
            <Input
              name="name"
              onChange={handleInputChange}
              title="Nombre de la persona de contacto"
              type="text"
              value={formData.name}
            />
          </div>
          <div className="contact-us-input">
            <Input
              name="email"
              onChange={handleInputChange}
              title="Email de contacto"
              type="email"
              value={formData.email}
            />
          </div>

          <div className="contact-us-input">
            <Input
              name="phone"
              onChange={handleInputChange}
              title="Teléfono de contacto"
              type="tel"
              value={formData.phone}
            />
          </div>

          <div className="contact-us-input">
            <Input
              name="message"
              onChange={handleInputChange}
              title="Cuéntanos sobre vosotros"
              type="textarea"
              value={formData.message}
            />
          </div>

          <div className="contact-us-input">
            <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
            Estoy de acuerdo con los
            <Link
              onClick={() =>
                window.open(
                  `https://wezircle.s3.eu-west-3.amazonaws.com/privacy.pdf`,
                  '_blank'
                )
              }
              text="términos y condiciones"
            />
          </div>
        </form>
        <Button
          onClick={handleSubmit}
          disabled={!isFormComplete}
          text="Continuar"
        />
      </div>
    </AdaptiveDialog>
  );
};

export default ContactUs;
