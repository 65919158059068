import React, { useState } from 'react';
import './JoinZircle.scss';
import { AdaptiveSidenav } from '../../../reusable';
import JoinZircleFirstStep from './steps/JoinZircleFirstStep';
import JoinZircleSecondStep from './steps/JoinZircleSecondStep';
import JoinZircleThirdStep from './steps/JoinZircleThirdStep';
import { useNavigate } from 'react-router-dom';

export interface UserData {
  code: string;
  email: string;
  password: string;
  phone: string;
  username: string;
  zircleName: string;
}

enum WizardStep {
  Search,
  SignUp,
  Verification,
}

function JoinZircle() {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);
  const [currentStep, setCurrentStep] = useState<WizardStep>(WizardStep.Search);
  const [userData, setUserData] = useState<UserData>({
    code: '',
    email: '',
    password: '',
    phone: '',
    username: '',
    zircleName: '',
  });

  const handlePrevStep = () => {
    setCurrentStep(
      currentStep === WizardStep.Verification
        ? WizardStep.SignUp
        : WizardStep.Search
    );
  };
  const handleNextStep = (data: Partial<UserData>) => {
    switch (currentStep) {
      case WizardStep.SignUp:
        setUserData(prev => ({
          ...prev,
          email: data.email ?? '',
          password: data.password ?? '',
          phone: data.phone ?? '',
          username: data.username ?? '',
        }));
        setCurrentStep(WizardStep.Verification);
        break;
      case WizardStep.Verification:
        setUserData(prev => ({ ...prev, code: data.code ?? '' }));
        navigate('/login');
        break;
      case WizardStep.Search:
      default:
        setUserData(prev => ({ ...prev, zircleName: data.zircleName ?? '' }));
        setCurrentStep(WizardStep.SignUp);

        break;
    }
  };

  return isVisible ? (
    <AdaptiveSidenav onBlur={() => setIsVisible(false)}>
      <div className="join-zircle-container">
        {currentStep === WizardStep.Search && (
          <JoinZircleFirstStep onNextStep={handleNextStep} />
        )}
        {currentStep === WizardStep.SignUp && (
          <JoinZircleSecondStep
            initialData={{
              email: userData.email,
              password: userData.password,
              phone: userData.phone,
              username: userData.username,
            }}
            onNextStep={handleNextStep}
            zircle={userData.zircleName}
          />
        )}
        {currentStep === WizardStep.Verification && (
          <JoinZircleThirdStep
            email={userData.email}
            onNextStep={handleNextStep}
            onPrevStep={handlePrevStep}
            phone={userData.phone}
            username={userData.username}
            zircle={userData.zircleName}
          />
        )}
      </div>
    </AdaptiveSidenav>
  ) : (
    <React.Fragment></React.Fragment>
  );
}

export { JoinZircle };
